import { Newspaper } from "lucide-react";
import Button from "../../Button";

function NewsItem({ date, title, description, imageUrl }) {
  return (
    <div className="flex items-start justify-between py-4 border-b border-gray-200 last:border-b-0">
      <div className="flex-1 pr-4 h-20">
        <p className="text-xs text-gray-500">{date}</p>
        <h3 className="font-helveticaNeue font-medium text-sm mt-1 line-clamp-2">{title}</h3>
        <p className="font-helveticaNeue font-normal text-[13px] text-gray-600 mt-1 line-clamp-1">{description}</p>
      </div>
      <img
        src={imageUrl}
        alt={title}
        className="w-20 h-20 rounded-lg object-cover flex-shrink-0"
      />
    </div>
  );
}

export default function CompanyNewsFeed() {
  const newsItems = [
    {
      date: "30/12/2024",
      title: "The Ivy collection unveils its new £5 million Belfast restaurant",
      description: "Experience the elegance of The Ivy Collection...",
      imageUrl: "https://picsum.photos/200/300",
    },
    {
      date: "30/12/2024",
      title: "Tycoon Caring close to clinching £1Bn sale of Ivy Collection",
      description: "Richard Caring, the renowned businessman, is...",
      imageUrl: "https://picsum.photos/200/300",
    },
    {
      date: "30/12/2024",
      title:
        "The Ivy Collection set to open new restaurant in Bournemouth this autumn",
      description: "The Ivy Collection is bringing its signature style...",
      imageUrl: "https://picsum.photos/200/300",
    },
    {
      date: "30/12/2024",
      title:
        "The Ivy Collection set to open new restaurant in Bournemouth this autumn",
      description: "The Ivy Collection is bringing its signature style...",
      imageUrl: "https://picsum.photos/200/300",
    },
    {
      date: "30/12/2024",
      title:
        "The Ivy Collection set to open new restaurant in Bournemouth this autumn",
      description: "The Ivy Collection is bringing its signature style...",
      imageUrl: "https://picsum.photos/200/300",
    },
    {
      date: "30/12/2024",
      title:
        "The Ivy Collection set to open new restaurant in Bournemouth this autumn",
      description: "The Ivy Collection is bringing its signature style...",
      imageUrl: "https://picsum.photos/200/300",
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4 flex items-center gap-2 border-b border-gray-200">
        <Newspaper className="w-5 h-5 text-gray-500" />
        <h2 className="text-sm font-medium">Company News Feed</h2>
      </div>
      <div className="divide-y divide-gray-200 max-h-[610px] overflow-hidden">
        {newsItems.map((item, index) => (
          <div key={index} className="px-4">
            <NewsItem {...item} />
          </div>
        ))}
      </div>
      <div className=" border-gray-200 w-full p-4 shadow-white">
        <Button variant="naked" className="w-full">
          View More
        </Button>
      </div>
    </div>
  );
}
