import React from "react";

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="w-4 h-4 text-blue-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
      />
      <span className="ml-2 text-sm font-normal text-gray-500">{label}</span>
    </label>
  );
};

export default Checkbox;
