import {
  BookKey,
  FileText,
  House,
  Layers2,
  MessageCircleMore,
} from "lucide-react";

export const companyTabData = [
  {
    label: "Home",
    key: "/home",
    icon: <House className="w-4 h-4" />,
  },
//  {
//    label: "Engagements",
//    key: "/engagements",
//    icon: <FileText className="w-4 h-4" />,
//  },
//  {
//    label: "Access/Staffing",
//    key: "/access",
//    icon: <BookKey className="w-4 h-4" />,
//  },
];

export const engagementsTabData = [
  {
    label: "Home",
    key: "/engagements/home",
    icon: <House className="w-4 h-4" />,
  },
  {
    label: "Workstreams",
    key: "/engagements/workstreams",
    icon: <Layers2 className="w-4 h-4" />,
  },
  {
    label: "Documents",
    key: "/engagements/documents",
    icon: <FileText className="w-4 h-4" />,
  },
];

export const workstreamTabData = [
  {
    label: "Home",
    key: "/engagements/workstreams/home",
    icon: <House className="w-4 h-4" />,
  },
  {
    label: "Chatbot",
    key: "/engagements/workstreams/chatbot",
    icon: <MessageCircleMore className="w-4 h-4" />,
  },
];

export const documentPreviewTabData = [
  {
    label: "Overview",
    key: "/engagements/documents/overview",
    icon: <FileText className="w-4 h-4" />,
  },
  {
    label: "Chatbot",
    key: "/engagements/documents/chatbot",
    icon: <MessageCircleMore className="w-4 h-4" />,
  },
];
