import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

const Input = ({ label, type, id, name, placeholder, required, formik }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="space-y-1.5">
      <label className="block text-sm font-medium" htmlFor={id}>
        {label} {required ? <span className="text-red-500">*</span> : null}
      </label>
      <div className="relative">
        <input
          type={type === "password" && showPassword ? "text" : type}
          name={name}
          id={id}
          placeholder={placeholder}
          className="bg-white border border-gray-200 rounded-md w-full p-3 text-base placeholder:text-gray-400"
          required={required}
          {...formik.getFieldProps(name)}
        />
        {type == "password" ? (
          <span
            className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer text-gray-400"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
