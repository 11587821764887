import React from "react";

const StepperItem = ({ date, title, active, isLast }) => {
  return (
    <div className="flex gap-3">
      <div className="flex flex-col items-center">
        <div
          className={`relative w-5 h-6 rounded-full  flex justify-center items-center
          ${active ? "bg-blue-100" : "bg-gray-100"}
        `}
        >
          <div
            className={`w-2 h-2 rounded-full sha  ${
              active ? "bg-blue-500" : "bg-gray-500"
            } relative z-10`}
          />
        </div>
        {!isLast && (
          <div className="w-0.5 h-full border border-dashed -mt-2 mb-2" />
        )}
      </div>
      <div className="pb-8 mt-0.5">
        <p className="text-xs text-secondary mb-1 font-helveticaNeue">{date}</p>
        <p className="text-primary text-sm font-normal font-helveticaNeue">
          {title}
        </p>
      </div>
    </div>
  );
};

export default StepperItem;
