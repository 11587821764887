import React from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

const DefaultLayout = ({ children }) => {
  return (
    <div className="flex h-screen bg-slate-50 text-sm font-medium">
      <Sidebar />
      <div className="flex-1 h-full md:h-screen overflow-auto">
        <Header />
        <div className="bg-white min-h-[calc(100vh-3.5rem)] rounded-lg p-5">
            {children}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
