import { useParams, useLocation, useNavigate } from "react-router-dom";

export function Tabs({ data }) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = location.pathname;
  const { name } = useParams();

  const activeTabIndex = data?.findIndex((tab) => tab.key === activeTab);

  const handleChangeTab = (key) => {
    navigate(`/${name}${key}?${queryParams.toString()}`);
  };

  return (
    <div className="border-b-2 -mx-5 px-5">
      <div className="flex">
        {/* Loop through tab data and render button for each */}
        {data.map((tab, idx) => (
          <button
            key={idx}
            className={`pb-3 border-b-2 -mb-[2px] transition-colors duration-300  ${
              idx === activeTabIndex
                ? "border-blue-500"
                : "border-transparent hover:border-gray-200"
            }`}
            // Change the active tab on click
            onClick={() => handleChangeTab(tab.key)}
          >
            <span
              className={`px-4 py-2 flex gap-1 items-center rounded  ${
                idx === activeTabIndex
                  ? "text-blue-600 bg-blue-50"
                  : "text-secondary"
              } `}
            >
              {tab.icon}
              {tab.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}
