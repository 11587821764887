const buttonVariants = {
  primary: "bg-[#1971F6] text-[#F1F6FE] hover:bg-[#1658c1]",
  secondary: "bg-[#F1F6FE] text-[#1971F6] hover:bg-[#E1E4EA]",
  naked: "bg-white text-[#626262] border border-[#E1E4EA] hover:bg-gray-100",
};
const Button = ({
  children,
  variant = "primary",
  onClick = () => {},
  className = "",
}) => {
  return (
    <button
      className={`rounded-[4px] py-1.5 px-4 font-medium ${buttonVariants[variant]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
