import React, { useState } from "react";
import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button";

import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { columns } from "./data";
import { Tabs } from "../../../components/Tabs";

import { Plus, SlidersHorizontal } from "lucide-react";
import { engagementsTabData } from "../../../constants/tabs";
import EngagementsHeader from "../header";

const EngagementsWorkstream = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { name } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const slug = queryParams.get("slug");
  const [files, setFiles] = useState([
    {
      id: "1",
      name: "Workforce Optimization",
      owner: "John Doe",
      start_date: "01/01/2023",
      end_date: "02/01/2023",
      status: "IN_PROGRESS",
    },
    {
      id: "2",
      name: "Technology and Automation",
      owner: "John Doe 2",
      start_date: "01/01/2023",
      end_date: "02/01/2023",
      status: "IN_PROGRESS",
    },
    {
      id: "3",
      name: "Business Planning",
      owner: "John Doe 3",
      start_date: "01/01/2023",
      end_date: "02/01/2023",
      status: "IN_PROGRESS",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleQuery = (query) => {
    console.log("q:", query);
  };

  const handleRowClick = (workstream) => {
    navigate(`/${name}/engagements/workstreams/home?slug=${slug}&id=${id}&workstream=${workstream}`);
  };

  return (
    <div className="rounded-md text-primary">
      <EngagementsHeader />
      <Tabs data={engagementsTabData} />
      <div className="rounded-md border bg-[#FDFEFF] mt-6">
        <div className="flex justify-between w-full mt-4 pr-4">
          <div className="pl-6 flex align-middle gap-2 ">
            <span className="text-[16px] my-auto">Workstreams</span>
          </div>
          <div className="flex gap-2">
            <Button variant="naked">
              <div className="flex gap-2">
                <div className="my-auto">
                  <SlidersHorizontal className="w-3 h-3" />
                </div>{" "}
                Filter
              </div>
            </Button>
            <Button>
              <div className="flex gap-2">
                <div className="my-auto">
                  <Plus className="w-3 h-3" />
                </div>{" "}
                Add New Workstreams
              </div>
            </Button>
          </div>
        </div>

        <DataTable
          loading={loading}
          columns={columns({ handleRowClick })}
          data={files}
          onQuery={handleQuery}
          className="mt-4"
        />
      </div>
    </div>
  );
};

export default EngagementsWorkstream;
