import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const authKey = localStorage.getItem("auth_key");

  if (!authKey) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

export default ProtectedRoute;