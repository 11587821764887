import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const authKey = localStorage.getItem("auth_key");

  if (authKey) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PublicRoute;
