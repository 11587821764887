import React, { useRef, useEffect } from "react";
import SendSvg from "../../icons/SendSvg";

const ExpandableInput = ({
  value,
  onChange,
  onSend,
  placeholder = "Message [AI Name]",
  disabled = false,
}) => {
  const textareaRef = useRef();

  // Auto-resize textarea
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to correctly calculate scrollHeight
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
    }
  }, [value]);

  // Handle key events for sending message
  const handleKeyDown = (e) => {
    // Send on Enter without Shift key
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend(e);
    }
    // Allow line breaks with Shift+Enter
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const value = textarea.value;

      // Insert line break
      textarea.value = value.substring(0, start) + "\n" + value.substring(end);
      textarea.selectionStart = textarea.selectionEnd = start + 1;

      // Trigger onChange manually
      if (onChange) {
        onChange({ target: textarea });
      }
    }
  };

  return (
    <div className="relative w-full flex items-end">
      <textarea
        ref={textareaRef}
        className="
          p-3 
          w-full 
          bg-[#F9FBFF] 
          border 
          border-[#D6E0E7] 
          hover:border-blue-300 
          focus:border-blue-300 
          focus:outline-none 
          active:border-blue-300 
          text-primary 
          rounded-[4px]
          pr-10
          resize-none
          min-h-[40px]
          max-h-[120px]
          overflow-y-auto
          scrollbar-thin
          scrollbar-thumb-gray-300
          scrollbar-track-gray-100
        "
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        rows={1}
      />
      <div
        className="
          absolute 
          right-0 
          pr-3 
          flex 
          items-center 
          cursor-pointer
          hover:text-blue-500
          transition-colors 
          duration-200
          h-full
        "
        onClick={onSend}
      >
        <SendSvg className="h-5 w-5 text-gray-500 m-auto" />
      </div>
    </div>
  );
};

export default ExpandableInput;
