import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button";

function DocumentPrompt() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [promptText, setPromptText] = useState("");

  const [step, setStep] = useState(() => {
    const stepParam = searchParams.get("step");
    return stepParam ? parseInt(stepParam, 10) : 2;
  });

  const changeStep = (newStep) => {
    // Update step and directly set query param
    setStep(newStep);
    searchParams.set("step", newStep.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleQuickPromptClick = (text) => {
    // Set the input text to the clicked button's text
    setPromptText(text);
  };

  return (
    <div className="border border-gray-200 rounded-lg p-6 mt-6">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h1 className="text-[16px] text-gray-900">Create Your Prompt</h1>
          <p className="text-gray-500 text-sm mt-1">
            Provide a clear and specific prompt to continue with your request.
          </p>
        </div>
        <div className="flex gap-3">
          <Button variant="naked" onClick={() => changeStep(1)}>
            Back
          </Button>
          <Button onClick={() => changeStep(3)}>Continue</Button>
        </div>
      </div>

      <hr className="mb-4 -mx-6" />

      <div className="space-y-4 bg-bgbase p-4 rounded-md">
        <h2 className="text-[15px] font-medium text-gray-900">
          Write a prompt
        </h2>
        <input
          type="text"
          placeholder="Type your request here, e.g., 'List required documents'"
          className="w-full p-3 rounded-lg border border-gray-200 text-primary placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:border-blue-400"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
        />

        <div className="grid grid-cols-3 gap-4 mt-4">
          {[
            "List out documents required for financial analysis.",
            "Generate a checklist for company valuation.",
            "Provide me a list for a commercial due diligence.",
          ].map((text, index) => (
            <button
              key={index}
              className="p-2 text-left rounded-lg bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-50 transition-colors"
              onClick={() => handleQuickPromptClick(text)}
            >
              {text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DocumentPrompt;
