import { postData } from "../../utils/apiUtils";

/**
 * Sends a POST request to the /placeholder endpoint with the provided input data.
 *
 * @param {any} input - The input data to be sent in the request body.
 * @returns {Promise<any>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const chatService = async (input) => {
  try {
    const response = await postData("/placeholder", { input });
    return response;
  } catch (error) {
    console.error("Error in placeholder request:", error);
    throw error;
  }
};
