import React, { useState } from "react";
import { Check } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button";

export default function DocumentCategory() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize step from URL query parameter, default to 1
  const [step, setStep] = useState(() => {
    const stepParam = searchParams.get("step");
    return stepParam ? parseInt(stepParam, 10) : 2;
  });

  const [selectedCategory, setSelectedCategory] = useState(
    "Financial Due Diligence"
  );

  const categories = [
    "Financial Due Diligence",
    "Legal Due Diligence",
    "Commercial Due Diligence",
    "Technical Due Diligence",
  ];

  const selectCategory = (category) => {
    searchParams.set("category", selectedCategory);
    setSearchParams(searchParams, { replace: true });
    setSelectedCategory(category);
  };

  const changeStep = (newStep) => {
    // Update step and directly set query param
    setStep(newStep);
    searchParams.set("step", newStep.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="border border-gray-200 rounded-lg p-6 mt-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-[16px] font-semibold text-gray-900">
            Choose project type
          </h1>
          <p className="text-gray-500 mt-1">
            Choose a project type and upload the required documents to proceed.
          </p>
        </div>
        <div className="flex gap-3">
          <Button variant="naked" onClick={() => changeStep(1)}>
            Back
          </Button>
          <Button onClick={() => changeStep(3)}>Continue</Button>
        </div>
      </div>

      <div className="space-y-3">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => selectCategory(category)}
            className={`w-full flex items-center gap-3 p-4 rounded-lg border border-gray-200 hover:border-[#1971F6] transition-all
              ${
                selectedCategory === category
                  ? "bg-[#F0F7FF] border-[#1971F6]"
                  : "bg-white"
              }
            `}
          >
            <div
              className={`
              w-5 h-5 rounded-full flex items-center justify-center border
              ${
                selectedCategory === category
                  ? "border-[#1971F6] bg-[#1971F6]"
                  : "border-gray-300"
              }
            `}
            >
              {selectedCategory === category && (
                <Check className="w-3 h-3 text-white stroke-[3]" />
              )}
            </div>
            <span className="text-gray-900 font-medium">{category}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
