import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { List, PencilLine } from "lucide-react";
import Button from "../../../../components/Button";

export default function UploadData() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize selected tab from URL query parameter, default to 'existing'
  const [selectedTab, setSelectedTab] = useState(() => {
    return searchParams.get("tab") || "existing";
  });

  // Initialize step from URL query parameter, default to 1
  const [step, setStep] = useState(() => {
    const stepParam = searchParams.get("step");
    return stepParam ? parseInt(stepParam, 10) : 1;
  });

  const changeStep = (newStep) => {
    // Update step and directly set query param
    setStep(newStep);
    searchParams.set("step", newStep.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleChangeTab = (tab) => {
    // Update tab and directly set query param
    setSelectedTab(tab);
    searchParams.set("tab", tab);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden mt-6">
      <div className="p-5">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-[16px]  text-gray-900">Upload Data</h1>
            <p className="text-gray-500 font-productSans mt-1">
              Please choose your preferred method.
            </p>
          </div>
          <Button onClick={() => changeStep(2)}> Continue</Button>
        </div>

        <hr className="border-t border-gray-200 mb-6" />

        <div className="grid grid-cols-2 gap-4">
          {/* Existing Data Tab */}
          <button
            onClick={() => handleChangeTab("existing")}
            className={`
              relative p-12 rounded-lg border text-center
              ${
                selectedTab === "existing"
                  ? "border-[#1971F6] bg-[#F0F7FF]"
                  : "border-gray-200 hover:border-gray-300"
              }
            `}
          >
            <div className="flex flex-col items-center gap-4">
              <div
                className={`
                p-2 rounded-lg
                ${selectedTab === "existing" ? "bg-[#1971F6]" : "bg-gray-100"}
              `}
              >
                <List
                  className={`w-6 h-6 ${
                    selectedTab === "existing" ? "text-white" : "text-gray-500"
                  }`}
                />
              </div>
              <div>
                <h2
                  className={`
                  text-lg font-medium mb-1
                  ${
                    selectedTab === "existing"
                      ? "text-[#1971F6]"
                      : "text-gray-900"
                  }
                `}
                >
                  Use existing data
                </h2>
                <p className="text-gray-500 text-sm">
                  Select from available lists
                </p>
              </div>
            </div>
          </button>

          {/* Generate List Tab */}
          <button
            onClick={() => handleChangeTab("generate")}
            className={`
              relative p-12 rounded-lg border text-center
              ${
                selectedTab === "generate"
                  ? "border-[#1971F6] bg-[#F0F7FF]"
                  : "border-gray-200 hover:border-gray-300"
              }
            `}
          >
            <div className="flex flex-col items-center gap-4">
              <div
                className={`
                p-2 rounded-lg
                ${selectedTab === "generate" ? "bg-[#1971F6]" : "bg-gray-100"}
              `}
              >
                <PencilLine
                  className={`w-6 h-6 ${
                    selectedTab === "generate" ? "text-white" : "text-gray-500"
                  }`}
                />
              </div>
              <div>
                <h2
                  className={`
                  text-lg font-medium mb-1
                  ${
                    selectedTab === "generate"
                      ? "text-[#1971F6]"
                      : "text-gray-900"
                  }
                `}
                >
                  Generate a list
                </h2>
                <p className="text-gray-500 text-sm">
                  Create one based on a query
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
