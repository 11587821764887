import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("auth_key");
    navigate("/auth/login");
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-[500px]">
        <p>Logging out...</p>
    </div>
  );
};

export default Logout;
