import Button from "../../../../components/Button";
import DocsFillSvg from "../../../../icons/DocsFillSvg";

const renderStatus = (status) => {
  if (status === "IN_PROGRESS") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-blue-500 bg-blue-100 border rounded border-blue-200 py-1 px-2 !text-xs">
          In Progress
        </div>
      </div>
    );
  }

  if (status === "COMPLETED") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-green-600 bg-green-100 border rounded border-green-200 py-1 px-2 !text-xs">
          Completed
        </div>
      </div>
    );
  }

  if (status === "NOT_STARTED") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-orange-400 bg-orange-100 border rounded border-orange-200 py-1 px-2 !text-xs">
          Not Started
        </div>
      </div>
    );
  }

  if (status === "ON_HOLD") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-red-500 bg-red-100 border rounded border-red-200 py-1 px-2 !text-xs">
          On Hold
        </div>
      </div>
    );
  }
};

export const renderDoc = (type, text) => {
  if (type === "pdf") {
    return (
      <div className="my-auto font-productSans border rounded text-orange-600 bg-orange-100  border-orange-200 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-orange-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="my-auto font-productSans border rounded text-green-600 bg-green-100  border-green-200 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="my-auto text-blue-600 bg-blue-100  border-blue-200 font-productSans border rounded  py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-blue-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }
  return (
    <div className="my-auto font-productSans border rounded border-gray-400 py-1 px-2 !text-xs flex gap-1">
      <span className="m-auto text-gray-600">
        <DocsFillSvg />
      </span>
      <b className="uppercase">{text}</b>
    </div>
  );
};

export const columnsProject = ({ handleRowClick }) => [
  { key: "name", label: "Name" },
  { key: "delivery_date", label: "Delivery Date" },
  {
    key: "expectation",
    label: "Expectation",
  },
  {
    key: "delivery_status",
    label: "Delivery Status",
    render: (status) => renderStatus(status),
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, { delivery_status, name }) => {
      if (delivery_status === "COMPLETED") {
        return renderDoc("doc", "lorep ipsum.doc");
      }
      return (
        <div className="flex gap-2">
          <Button variant="naked" onClick={() => handleRowClick(name)}>
            Upload Document
          </Button>
        </div>
      );
    },
  },
];

export const columnsAnalys = ({ handleRowClick }) => [
  { key: "name", label: "Name" },
  { key: "date", label: "Date" },
  {
    key: "share_by",
    label: "Share By",
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, data) => (
      <div className="flex gap-2">
        <Button variant="naked" onClick={() => handleRowClick(data.name)}>
          View
        </Button>
      </div>
    ),
  },
];
