import React, { useState } from "react";
import Input from "../../../components/Form/Input";
import Checkbox from "../../../components/Form/Checkbox";
import { NavLink, useNavigate } from "react-router-dom";
import { loginService } from "../../../service/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await loginService(values);
        const auth_key = localStorage.getItem('auth_key')
        if(response && auth_key) {
          navigate('/')
        }
      } catch (error) {
        console.error("Error login:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="lg:flex h-screen roboto">
      <div className="relative w-full bg-tosca py-10 lg:py-[70px] px-10 lg:px-[100px] overflow-hidden">
        <img
          src="/images/login/image-login-1.webp"
          alt="Image"
          className="absolute top-0 right-0"
        />
        <img
          src="/images/login/image-login.webp"
          alt="Image"
          className="hidden lg:block absolute bottom-0 right-0 w-[500px]"
        />
        <div className="relative lg:w-2/3 space-y-4 lg:space-y-6">
          <img
            src="/images/logo-black.webp"
            alt="Logo"
            className="h-14 lg:h-[70px]"
          />
          <h1 className="text-2xl md:text-[32px] font-semibold text-black">
            Transforming Consulting
          </h1>
          <p className="text-sm md:text-base font-normal text-black">
            An AI-toolkit accelerating data collection and analysis for Management Consultants
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full p-8">
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md w-full lg:w-[500px] p-10 lg:p-[52px] space-y-8"
        >
          <div className="text-center space-y-3 lg:space-y-4">
            <h1 className="text-4xl lg:text-5xl font-normal text-black">
              Login
            </h1>
            <p className="text-sm lg:text-base font-normal text-gray-500">
              Welcome back! Please enter your details
            </p>
          </div>
          <div>
            <Input
              id="username"
              label="Username"
              type="text"
              name="username"
              placeholder="Enter username"
              required={true}
              formik={formik}
            />
            <div className="h-6"></div>
            <Input
              id="password"
              label="Password"
              type="password"
              name="password"
              placeholder="Enter Password"
              required={true}
              formik={formik}
            />
            <div className="h-4"></div>
            <div className="flex items-center justify-between">
              <Checkbox
                id="remember"
                label="Remember Me"
                name="remember"
                required={false}
              />
              <a
                href="mailto:info@intriq.ai"
                className="text-sm font-medium text-tosca"
              >
                Forgot Password? Contact Support
              </a>
            </div>
          </div>
          <div className="space-y-4">
            {!loading ? (
              <button
                type="submit"
                className="w-full mt-2 bg-tosca rounded-md py-3 text-black text-base font-medium"
              >
                Login
              </button>
            ) : (
              <button type="button" className="w-full mt-2 bg-bgbase rounded-md py-3 text-secondary text-base font-medium">
                Login
              </button>
            )}
            <div className="text-center text-sm font-normal text-gray-500">
              Don’t have an account?
              <span>
                <a
                  href="mailto:info@intriq.ai"
                  className="text-sm font-medium text-tosca ml-1"
                >
                  Contact Us
                </a>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
