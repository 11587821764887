import React, { useState } from "react";
import MagicSvg from "../../icons/MagicSvg";

const Tooltip = ({ children, text, position = "top" }) => {
  const [isVisible, setIsVisible] = useState(false);

  const positionStyles = {
    top: "bottom-full left-1/2 -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 -translate-x-1/2 mt-2",
    left: "right-full top-1/2 -translate-y-1/2 mr-2",
    right: "left-full top-1/2 -translate-y-1/2 ml-2",
  };

  return (
    <div className="relative inline-block group z-20">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="inline-block"
      >
        {children}
      </div>

      {isVisible && (
        <div
          className={`
            flex
            gap-1
            absolute z-10 
            bg-gray-800 
            text-[#E1FF01]
            text-xs 
            px-3 py-2 
            rounded-md 
            whitespace-nowrap 
            transition-all 
            duration-300 
            ${positionStyles[position]}
          `}
        >
          <MagicSvg />
          {text}
          <div
            className={`
              absolute 
              w-0 h-0 
              border-4 
              ${
                position === "top"
                  ? "border-b-0 border-t-gray-800 top-full"
                  : ""
              }
              ${
                position === "bottom"
                  ? "border-t-0 border-b-gray-800 bottom-full"
                  : ""
              }
              ${
                position === "left"
                  ? "border-r-0 border-l-gray-800 left-full"
                  : ""
              }
              ${
                position === "right"
                  ? "border-l-0 border-r-gray-800 right-full"
                  : ""
              }
              border-transparent
              left-1/2 
              -translate-x-1/2
            `}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
