import React, { useState } from "react";
import DataTable from "../../../../components/DataTable";
import Button from "../../../../components/Button";

import { useNavigate } from "react-router-dom";
import { Tabs } from "../../../../components/Tabs";

import { Plus, SlidersHorizontal } from "lucide-react";
import { workstreamTabData } from "../../../../constants/tabs";
import EngagementsHeader from "../header";
import DocsSvg from "../../../../icons/DocsSvg";
import { columnsProject } from "./data";
import { columnsAnalys } from "./data";
import AddDeliverableModal from "./modal";

const EngagementsWorkstreamHome = () => {
  const [showModal, setShowModal] = useState(false);
  const [analys, setAnalys] = useState([
    {
      id: "1",
      name: "Analysis of Sales ",
      date: "01/01/2023",
      share_by: "John Doe 3",
      status: "IN_PROGRESS",
    },
    {
      id: "2",
      name: "Financial Consultation",
      date: "01/01/2023",
      share_by: "John Doe 3",
      status: "IN_PROGRESS",
    },
    {
      id: "3",
      name: "Jane Smith 3 ",
      share_by: "John Doe 3",
      date: "01/01/2023",
      status: "IN_PROGRESS",
    },
  ]);

  const [project, setProject] = useState([
    {
      id: "1",
      name: "Quality Management",
      delivery_date: "01/01/2023",
      expectation:
        "All deliverables must meet the predefined quality standards.",
      delivery_status: "IN_PROGRESS",
    },
    {
      id: "2",
      name: "Analytics and Reporting",
      delivery_date: "01/01/2023",
      expectation:
        "All deliverables must meet the predefined quality standards.",
      delivery_status: "COMPLETED",
    },
    {
      id: "3",
      name: "Trainig & Development",
      delivery_date: "01/01/2023",
      expectation:
        "All deliverables must meet the predefined quality standards.",
      delivery_status: "ON_HOLD",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const handleQuery = (query) => {
    console.log("q:", query);
  };

  const handleRowClick = (name) => {};

  return (
    <>
      <AddDeliverableModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
      <div className="rounded-md text-primary">
        <EngagementsHeader />
        <Tabs data={workstreamTabData} />
        <div className="rounded-md border bg-[#FDFEFF] mt-6">
          <div className="flex justify-between w-full mt-4 pr-4 mb-4">
            <div className="pl-6 flex align-middle gap-2 ">
              <span className="text-[16px] my-auto">Summary of Workstream</span>
            </div>
          </div>
          <div className="px-6 py-4 border-t border-borderbase">
            Engagement to analyze how to sustainably reduce staffing costs
            across the hospitality chain by improving operational efficiency,
            leveraging technology, and optimizing workforce structures. This
            will be achieved without compromising service quality or compliance
            with UK labor regulations
          </div>
        </div>
        <div className="rounded-md border bg-[#FDFEFF] mt-6">
          <div className="flex justify-between w-full mt-4 pr-4">
            <div className="pl-6 flex align-middle gap-2 ">
              <span className="text-[16px] my-auto">Projects Deliverable</span>
            </div>
            <div className="flex gap-2">
              <Button onClick={() => setShowModal(true)}>
                <div className="flex gap-2">
                  <div className="my-auto">
                    <Plus className="w-3 h-3" />
                  </div>{" "}
                  Add New Deliverable
                </div>
              </Button>
            </div>
          </div>

          <DataTable
            loading={loading}
            columns={columnsProject({ handleRowClick })}
            data={project}
            onQuery={handleQuery}
            className="mt-4"
          />
        </div>

        <div className="rounded-md border bg-[#FDFEFF] mt-6">
          <div className="flex justify-between w-full mt-4 pr-4">
            <div className="pl-6 flex align-middle gap-2 items-center ">
              <DocsSvg className="text-secondary" />
              <span className="text-[16px] my-auto">Shared Analysis</span>
            </div>
          </div>

          <DataTable
            loading={loading}
            columns={columnsAnalys({ handleRowClick })}
            data={analys}
            onQuery={handleQuery}
            className="mt-4"
          />
        </div>
      </div>
    </>
  );
};

export default EngagementsWorkstreamHome;
