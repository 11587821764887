import Button from "../../../components/Button";
import DocsFillSvg from "../../../icons/DocsFillSvg";

const renderStatus = (status) => {
  if (status === "IN_PROGRESS") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-orange-400 bg-orange-100 border rounded border-orange-200 py-1 px-2 !text-xs">
          In Progress
        </div>
      </div>
    );
  }

  if (status === "QUERY") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-green-400 border rounded border-green-400 py-1 px-2 !text-xs">
          <b>Query</b>
        </div>
      </div>
    );
  }
  return (
    <div className="flex gap-2 uppercase">
      <div className=" bg-green-200 p-1 rounded">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
      </div>
      <div className="my-auto">{status}</div>
    </div>
  );
};

export const columns = ({ handleRowClick }) => [
  { key: "name", label: "Name" },
  {
    key: "owner",
    label: "Owner",
  },
  { key: "start_date", label: "Start Date" },
  {
    key: "end_date",
    label: "End Date",
  },
  {
    key: "status",
    label: "Status",
    render: (status) => renderStatus(status),
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, data) => (
      <div className="flex gap-2">
        <Button variant="naked" onClick={() => handleRowClick(data.name)}>
          View Detail
        </Button>
      </div>
    ),
  },
];
