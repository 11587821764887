import React, { useState, useEffect } from "react";
import DataTable from "../../components/DataTable";
import Button from "../../components/Button";
import { getCompanyInfoService } from "../../service/home";

import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { columns } from "./data";
import { Tabs } from "../../components/Tabs";

import { Plus, SlidersHorizontal } from "lucide-react";
import { companyTabData } from "../../constants/tabs";
import AddEngagementModal from "./modal/add";

const Engagements = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { name } = useParams();
  const [company, setCompany] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [files, setFiles] = useState([
    {
      id: "1",
      engagement_name: "Project Alpha",
      owner: "John Doe",
      key_client_contact: "Jane Smith",
      start_date: "01/01/2023",
      engagement_length: "6 months",
      upload_contract: "File.xls",
      status: "Active",
    },
    {
      id: "2",
      engagement_name: "Project Beta",
      owner: "John Doe",
      key_client_contact: "Jane Smith",
      start_date: "01/01/2023",
      engagement_length: "6 months",
      upload_contract: "File.xls",
      status: "Active",
    },
    {
      id: "3",
      engagement_name: "Project Gamma",
      owner: "John Doe",
      key_client_contact: "Jane Smith",
      start_date: "01/01/2023",
      engagement_length: "6 months",
      upload_contract: "File.xls",
      status: "Active",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleQuery = (query) => {
    console.log("q:", query);
  };

  const handleRowClick = (slug) => {
    navigate(`/${name}/engagements/home?slug=${slug}&id=${id}`);
  };

  const fetchCompanyById = async (id) => {
    try {
      const response = await getCompanyInfoService(id);
      setCompany(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanyById(id);
  }, [id]);

  return (
    <div className="rounded-md text-primary">
      <div className="text-xl font-medium  text-blue-500 flex align-middle  mb-6 gap-2">
        <span className="text-primary font-helveticaNeue font-medium">
          {company?.name ? (
            company?.name
          ) : (
            <span className="text-white">-</span>
          )}
        </span>
      </div>

      <AddEngagementModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Tabs data={companyTabData} />
      <div className="rounded-md border bg-[#FDFEFF] mt-6">
        <div className="flex justify-between w-full mt-4 pr-4">
          <div className="pl-6 flex align-middle gap-2 ">
            <span className="text-lg my-auto font-helveticaNeue font-medium">
              Engagements
            </span>
          </div>
          <div className="flex gap-2">
            <Button variant="naked">
              <div className="flex gap-2">
                <div className="my-auto">
                  <SlidersHorizontal className="w-3 h-3" />
                </div>{" "}
                Filter
              </div>
            </Button>
            <Button onClick={() => setOpenModal(true)}>
              <div className="flex gap-2">
                <div className="my-auto">
                  <Plus className="w-3 h-3" />
                </div>{" "}
                Add New Engagements
              </div>
            </Button>
          </div>
        </div>

        <DataTable
          loading={loading}
          columns={columns({ handleRowClick })}
          data={files}
          onQuery={handleQuery}
          className="mt-4"
        />
      </div>
    </div>
  );
};

export default Engagements;
