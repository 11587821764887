import { ArrowLeft } from "lucide-react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

const EngagementsHeader = () => {
  const navigate = useNavigate();
  const { name } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const queryParams = new URLSearchParams(window.location.search);
  const workstream = queryParams.get("workstream");
  const slug = queryParams.get("slug");
  const goBack = () => {
    navigate(`/${name}/engagements/workstreams?slug=${slug}&id=${id}`);
  };
  return (
    <div className="text-xl font-medium  items-center text-blue-500 flex align-middle gap-2 mb-6">
      <ArrowLeft
        className="w-5 h-5 text-bold text-black cursor-pointer"
        onClick={goBack}
      />
      <span className="text-primary">{workstream}</span>
    </div>
  );
};

export default EngagementsHeader;
