import React from 'react';
import {
  Globe,
  BadgeCheck,
  SquareArrowOutUpRight,
  Target,
  Medal,
} from 'lucide-react';

export default function CompanyProfile({ company }) {

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
      <div
        className="h-32 bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/blank_header.png')",
        }}
      />
      <div className="p-6 flex items-start">
        <img
          src={company?.logo_file_url ? `/images/${company.logo_file_url}` : '/images/ivy-icon.png'}          alt="Ivy Collection"
          className="w-20 h-20 rounded-lg -mt-12 mr-4 object-cover"
        />
        <div className="flex-grow">
          <div className="flex items-center justify-between mb-1">
            <b className="text-2xl font-helveticaNeue font-medium flex items-center gap-1">
              {company?.name || '-'} <BadgeCheck className="text-white fill-blue-500" />
            </b>
          </div>
          <p className="text-gray-500 text-xs font-helveticaNeue font-light">{company?.founded_year || '-'} • {company?.city || '-'} ({company?.country || '-'})</p>
          <p className="mt-2 text-sm font-helveticaNeue font-normal">
            {company?.summary || '-'}
          </p>
        </div>
        <div className="ml-8 flex flex-col space-y-4 mr-10">
          <div className="text-left text-secondary font-helveticaNeue font-normal">Details</div>
          <div className="flex flex-col space-y-4 text-sm">
            <div className="flex items-center">
              <div className="p-1 border border-borderbase rounded-lg mr-2">
                <Globe className="w-4 h-4  text-gray-500" />
              </div>

              <div>
                <div className="font-helveticaNeue font-medium mr-2 text-secondary text-[10px]">
                  Website:
                </div>
                <a
                  href={`https://${company?.website || ''}`}
                  className="font-helveticaNeue font-medium text-sm text-primary flex items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://{company?.website || '-'}/{" "}
                  <SquareArrowOutUpRight className="w-3.5 h-3.5 text-blue-600" />
                </a>
              </div>
            </div>
            <div className="flex items-center">
              <div className="p-1 border border-borderbase rounded-lg mr-2">
                <Target className="w-4 h-4 text-gray-500" />
              </div>
              <div>
                <div className="font-helveticaNeue font-medium mr-2 text-secondary text-[10px]">
                  Sector:
                </div>
                <div className="font-helveticaNeue text-sm">{company?.industry || '-'}</div>
              </div>
            </div>
            {/*<div className="flex items-center">
              <div className="p-1 border border-borderbase rounded-lg mr-2">
                <Medal className="w-4 h-4 text-gray-500" />
              </div>
              <div>
                <div className="font-helveticaNeue font-medium mr-2 text-secondary text-[10px]">
                  Specialism:
                </div>
                <div className="font-helveticaNeue text-sm">TBD</div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
