import {
  Lightbulb,
  FileText,
  Utensils,
  Building2,
  Store,
  DollarSign,
  Wallet,
  MapPin,
  Truck,
  ChartPie,
  CircleDollarSign,
  Pizza,
} from "lucide-react";

function RevenueStreamItem({ icon, title, description }) {
  return (
    <div className="flex gap-4">
      <div className="shrink-0 w-8 h-8 flex items-center justify-center border rounded-md border-borderbase mt-1 ">
        {icon}
      </div>
      <div className="w-full">
        <h3 className="font-medium text-secondary mb-1">
          {title}
        </h3>
        {/* Use dangerouslySetInnerHTML to render the HTML inside description */}
        <p
          className="text-primary text-sm font-helveticaNeue font-normal leading-relaxed"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
}

function MetricCard({ icon, title, value, subtitle }) {
  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4">
      <div className="flex items-center gap-2 mb-4 ">
        <div className="bg-bgbase rounded-full p-1.5 text-primary">{icon}</div>

        <h3 className="text-gray-700 font-medium">{title}</h3>
      </div>
      <div className="space-y-1 bg-[#F8FAFD] -mx-4 -mb-4 rounded-b-lg  px-4 py-[26px] border-t border-[#E1E4EA]">
        <p className="text-2xl font-medium font-helveticaNeue">{value}</p>
        <p className="text-xs text-secondary">{subtitle}</p>
      </div>
    </div>
  );
}

export default function CompanyOverview({ company }) {
  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="p-4 border-b border-gray-200 flex items-center gap-2">
        <Lightbulb className="w-5 h-5 text-gray-500" />
        <h2 className="font-medium">Key Characteristics</h2>
      </div>

      <div className="p-4">
      {
        company?.snippets?.map((section, sectionIndex) => (
          <div key={sectionIndex} className="bg-white rounded-lg border border-gray-200 mb-3">
            <div className="p-4 border-b border-gray-200 flex items-center gap-2">
              <FileText className="w-5 h-5 text-gray-500" />
              <h3 className="font-medium">{section?.title}</h3> {/* Updated to use section.title */}
            </div>

            <div className="p-4 grid grid-cols-2 gap-5">
              {
                section?.content?.map((item, snippetIndex) => ( /* Updated to iterate over section.content */
                  <div key={snippetIndex} className="border-r border-gray-200 -mr-2.5 pr-3">
                    <RevenueStreamItem
                      icon={<span className="text-gray-500">{snippetIndex + 1}</span>}
                      title={item?.title}
                      description={item?.body}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }


        {/*<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <MetricCard
            icon={<DollarSign className="w-5 h-5" />}
            title="Total Revenue"
            value="$12,548,384"
            subtitle="FY 2023"
          />
          <MetricCard
            icon={<Wallet className="w-5 h-5 " />}
            title="Budgeted Revenue"
            value="$15,948,049"
            subtitle="FY 2023"
          />
          <MetricCard
            icon={<MapPin className="w-5 h-5 " />}
            title="Number of Locations"
            value="52"
            subtitle="Active accounts"
          />
          <MetricCard
            icon={<ChartPie className="w-5 h-5 " />}
            title="EBITDA Margin"
            value="6.8%"
            subtitle="Year-over-year growth of 2.3%"
          />
          <MetricCard
            icon={<CircleDollarSign className="w-5 h-5 " />}
            title="Forecasted 5-year Reven..."
            value="5.8%"
            subtitle="Year-over-year growth of 2.3%"
          />
          <MetricCard
            icon={<Truck className="w-5 h-5 " />}
            title="Number of Suppliers"
            value="82"
            subtitle="Across 12 countries"
          />
        </div>*/}
      </div>
    </div>
  );
}
