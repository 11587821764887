import Button from "../../../components/Button";
import DocsFillSvg from "../../../icons/DocsFillSvg";

export const renderIconDoc = (type, text) => {
  if (type === "pdf") {
    return (
      <div className="my-auto font-productSans  underline rounded text-[16px] py-1 px-2  flex gap-2">
        <span className="m-auto text-orange-600 bg-orange-100 p-1 rounded-md">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="my-auto font-productSans  underline rounded text-[16px] py-1 px-2  flex gap-2">
        <span className="m-auto text-green-600 bg-green-100 p-1 rounded-md">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="my-auto font-productSans  underline rounded text-[16px] py-1 px-2  flex gap-2">
        <span className="m-auto text-green-600 bg-green-100 p-1 rounded-md">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }
  return (
    <div className="my-auto font-productSans  underline rounded text-[16px] py-1 px-2  flex gap-2">
      <span className="m-auto text-orange-600 bg-orange-100 p-1 rounded-md">
        <DocsFillSvg />
      </span>
      {text}
    </div>
  );
};

export const columns = ({ handleRowClick }) => [
  {
    key: "name",
    label: "Name",
    render: (v, item) => (
      <div className="flex items-center">
        <img
          src={item?.img}
          alt="Avatar"
          className="w-6 h-6 rounded-full contain"
        />{" "}
        <span className="ml-2">{v}</span>
      </div>
    ),
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "date_added",
    label: "Date Added",
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, data) => (
      <div className="flex gap-2">
        <Button variant="naked" onClick={() => handleRowClick(data.email)}>
          Delete
        </Button>
      </div>
    ),
  },
];
