import Button from "../../../components/Button";
import DocsFillSvg from "../../../icons/DocsFillSvg";

const renderStatus = (status) => {
  if (status === "IN_PROGRESS") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-[#FFB932] border rounded border-[#FFEBC3] py-1 px-2 !text-xs">
          <b>Upload In progress</b>
        </div>
      </div>
    );
  }

  if (status === "QUERY") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-green-400 border rounded border-green-400 py-1 px-2 !text-xs">
          <b>Query</b>
        </div>
      </div>
    );
  }
  return (
    <div className="flex gap-2 uppercase">
      <div className=" bg-green-200 p-1 rounded">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
      </div>
      <div className="my-auto">{status}</div>
    </div>
  );
};

const renderDocType = (type) => {
  if (type === "pdf") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-orange-200 p-1 rounded">
          <span className="m-auto text-orange-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-green-200 p-1 rounded">
          <span className="m-auto text-green-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-blue-200 p-1 rounded">
          <span className="m-auto text-blue-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }
  return (
    <div className="flex gap-2 uppercase">
      <div className=" bg-gray-200 p-1 rounded">
        <span className="m-auto text-gray-600">
          <DocsFillSvg />
        </span>
      </div>
      <div className="my-auto"> {type}</div>
    </div>
  );
};

export const columnsRecive = ({ handleRowClick }) => [
  { key: "file_name", label: "Name of request" },
  {
    key: "summary",
    label: "Summary",
    render: (v) => "lorem ipsum dolor set amer".substring(0, 20) + "...",
  },
  {
    key: "document_type",
    label: "File Type",
    render: (type) => renderDocType(type),
  },

  {
    key: "status",
    label: "Status",
    render: (status) => renderStatus(status),
  },
  { key: "date_uploaded", label: "Date of Request" },
];

export const columnsRequest = ({ handleRowClick }) => [
  { key: "name_of_request", label: "Name of Request" },
  {
    key: "function",
    label: "Function",
    render: (fun) => (
      <div className="px-1 text-purple-500 bg-purple-100 border-purple-200 border rounded">
        {fun}
      </div>
    ),
  },
  {
    key: "date_of_request",
    label: "Date of Request",
  },
  {
    key: "email",
    label: "Client Email",
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, data) => (
      <div className="flex gap-2">
        <Button variant="naked" onClick={() => handleRowClick(data.file_name)}>
          Upload Document
        </Button>
      </div>
    ),
  },
];
