import React, { useState, useEffect } from "react";
import Dashboard from "./dashboard";
import { useSearchParams } from "react-router-dom";
import { getCompanyInfoService } from "../../service/home";

import { Tabs } from "../../components/Tabs";
import { companyTabData } from "../../constants/tabs";

const Home = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [company, setCompany] = useState(null);

  const fetchCompanyById = async (id) => {
    try {
      const response = await getCompanyInfoService(id);
      setCompany(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanyById(id);
  }, [id]);

  return (
    <div className="rounded-md text-primary">
      <div className="text-xl font-medium  text-blue-500 flex align-middle  gap-2 mb-6">
        <span className="text-primary font-helveticaNeue font-medium">
          {company?.name ? (
            company?.name
          ) : (
            <span className="text-white">-</span>
          )}
        </span>
      </div>
      <Tabs data={companyTabData} />
      <Dashboard company={company} />
    </div>
  );
};

export default Home;
