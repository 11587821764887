import React, { useState } from "react";
import {
  Upload,
  Mail,
  File,
  LayoutGrid,
  CalendarDays,
  CircleCheck,
} from "lucide-react";
import Button from "../../../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DocsFillSvg from "../../../../icons/DocsFillSvg";

export const renderDoc = (type, text, handlePreviewDoc) => {
  const docTypes = {
    pdf: { color: "orange", borderColor: "orange-200", bgColor: "orange-100" },
    xls: { color: "green", borderColor: "green-200", bgColor: "green-100" },
    doc: { color: "blue", borderColor: "blue-200", bgColor: "blue-100" },
  };

  const { color = "gray" } = docTypes[type] || {};

  return (
    <div
      className={`my-auto font-productSans border rounded text-primary underline bg-bgbase border-borderbase py-1.5 px-2  flex gap-2 cursor-pointer`}
      onClick={() => handlePreviewDoc(text)}
    >
      <span
        className={`m-auto text-${color}-600 p-1 bg-${color}-100 rounded-md`}
      >
        <DocsFillSvg />
      </span>
      {text}
    </div>
  );
};
function DocumentSubmit() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Initialize step from URL query parameter, default to 1
  const [step, setStep] = useState(() => {
    const stepParam = searchParams.get("step");
    return stepParam ? parseInt(stepParam, 10) : 2;
  });

  // State for items with more structured data
  const [items, setItems] = useState([
    {
      id: 1,
      category: "Financial",
      dateOfRequest: "01/12/2024",
      clientEmail: "jayce@gmail.com",
      dateSubmission: "05/12/2024",
      documentName: "Year to date - General Ledger",
      fileName: "year-to-date.xls",
      status: "completed",
      uploadable: false,
    },
    {
      id: 2,
      category: "Financial",
      dateOfRequest: "01/12/2024",
      clientEmail: "jayce@gmail.com",
      dateSubmission: "05/12/2024",
      documentName: "Prior three years Trial Balance summary by month",
      fileName: "balance-summary.pdf",
      status: "completed",
      uploadable: false,
    },
    {
      id: 3,
      category: "Financial",
      dateOfRequest: "01/12/2024",
      clientEmail: "jayce@gmail.com",
      dateSubmission: "05/12/2024",
      documentName: "Balance Sheet for last 3 years by year",
      fileName: "",
      status: "pending",
      uploadable: true,
    },
  ]);

  const changeStep = (newStep) => {
    // Update step and directly set query param
    setStep(newStep);
    searchParams.set("step", newStep.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleFileUpload = (id) => {
    // Logic for file upload can be added here
    console.log(`Uploading file for item ${id}`);
  };

  const handleFileChange = (id) => {
    // Logic for changing file can be added here
    console.log(`Changing file for item ${id}`);
  };

  const handlePreviewDoc = (data) => {
    navigate(
      `/engagements/documents/overview?${queryParams.toString()}&doc=${data}`
    );
  };

  return (
    <div className="mt-6 p-4 rounded-lg border border-borderbase">
      <div className=" mx-auto bg-white rounded-lg">
        <div className="flex justify-between items-center ">
          <h1 className="text-[16px] font-semibold">Upload Files</h1>
          <div className="flex gap-2">
            <Button variant="naked" onClick={() => changeStep(2)}>
              Back
            </Button>
            <Button onClick={() => changeStep(1)}>Done</Button>
          </div>
        </div>

        <p className="text-secondary mb-4">
          Upload files for each document as needed. Format: xls, pdf, or doc
          (max 125 GB)
        </p>

        <hr className="-mx-4 mb-4" />

        <div className="space-y-4">
          {/* Financial Due Diligence Section */}
          <h2 className="text-lg font-medium mb-4">Financial Due Diligence</h2>

          {/* Dynamically render items */}
          {items.map((item) => (
            <div
              key={item.id}
              className={`border rounded-lg p-6 bg-bgbase ${
                item?.fileName ? "border-green-500" : "border-[#E1E4EA]"
              }   `}
            >
              <div className="grid grid-cols-4 gap-4 mb-6">
                <div>
                  <p className="text-gray-500 text-sm mb-1">Category</p>
                  <div className="flex items-center gap-2">
                    <LayoutGrid size={20} className="text-gray-600" />
                    <span>{item.category}</span>
                  </div>
                </div>
                <div>
                  <p className="text-gray-500 text-sm mb-1">Date of Request</p>
                  <div className="flex items-center gap-2">
                    <CalendarDays size={20} className="text-gray-600" />
                    <span>{item.dateOfRequest}</span>
                  </div>
                </div>
                <div>
                  <p className="text-gray-500 text-sm mb-1">Client Email</p>
                  <div className="flex items-center gap-2">
                    <Mail size={20} className="text-gray-600" />
                    <span>{item.clientEmail}</span>
                  </div>
                </div>
                <div>
                  <p className="text-gray-500 text-sm mb-1">Date Submission</p>
                  <div className="flex items-center gap-2">
                    <CalendarDays size={20} className="text-gray-600" />
                    <span>{item.dateSubmission}</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between bg-white -m-4 p-4 rounded-md">
                <div className="flex items-center gap-2">
                  <span>{item.documentName}</span>
                  {item.status === "completed" && (
                    <CircleCheck
                      size={20}
                      className="text-white fill-[#2CAE70]"
                    />
                  )}
                </div>
                <div className="flex items-center gap-4">
                  {item.fileName && (
                    <div className="flex items-center gap-2">
                      {renderDoc(
                        item.fileName.split(".").pop(),
                        item.fileName,
                        handlePreviewDoc
                      )}
                    </div>
                  )}
                  {item.uploadable ? (
                    <Button
                      variant="naked"
                      className="flex gap-2 items-center content-center"
                      onClick={() => handleFileUpload(item.id)}
                    >
                      <Upload size={16} />
                      <span>Upload</span>
                    </Button>
                  ) : (
                    <Button
                      variant="naked"
                      className="flex gap-2 items-center content-center"
                      onClick={() => handleFileChange(item.id)}
                    >
                      <Upload size={16} />
                      Change
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DocumentSubmit;
