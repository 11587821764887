import { ArrowLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

const DocumentPreviewHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const goBack = () => {
    navigate(`/engagements/documents?${queryParams}`);
  };
  return (
    <div className="text-xl font-medium  items-center text-blue-500 flex align-middle gap-2 mb-6">
      <ArrowLeft
        className="w-5 h-5 text-bold text-black cursor-pointer"
        onClick={goBack}
      />
      <span className="text-primary">Document Preview</span>
    </div>
  );
};

export default DocumentPreviewHeader;
