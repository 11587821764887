import { getData, uploadFile } from "../../utils/apiUtils";

/**
 * Upload a file to the API.
 *
 * @param {File} file - The file to upload.
 * @param {Object} [additionalData={}] - Additional data to include in the request body.
 * @param {Function} [onProgress=null] - Optional progress tracking callback.
 * @returns {Promise<Object>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const uploadDocumentService = async (
  file,
  additionalData = {},
  onProgress = null
) => {
  try {
    const response = await uploadFile(
      "/upload_file",
      file,
      additionalData,
      onProgress
    );
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

/**
 * Retrieve a list of files from the API.
 *
 * @returns {Promise<Object>} The response data containing the list of files.
 * @throws Will throw an error if the request fails.
 */
export const listDocumentService = async () => {
  try {
    const response = await getData("/list_files");
    return response;
  } catch (error) {
    console.error("Error listing files:", error);
    throw error;
  }
};
