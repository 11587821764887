import Button from "../../components/Button";
import DocsFillSvg from "../../icons/DocsFillSvg";

export const renderDoc = (type, text) => {
  if (type === "pdf") {
    return (
      <div className="my-auto font-productSans border rounded text-orange-600 bg-orange-100  border-orange-200 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-orange-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="my-auto font-productSans border rounded text-green-600 bg-green-100  border-green-200 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="my-auto text-blue-600 bg-blue-100  border-blue-200 font-productSans border rounded  py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-blue-600">
          <DocsFillSvg />
        </span>
        {text}
      </div>
    );
  }
  return (
    <div className="my-auto font-productSans border rounded border-gray-400 py-1 px-2 !text-xs flex gap-1">
      <span className="m-auto text-gray-600">
        <DocsFillSvg />
      </span>
      <b className="uppercase">{text}</b>
    </div>
  );
};

export const columns = ({ handleRowClick }) => [
  { key: "engagement_name", label: "Engagement Name" },
  {
    key: "owner",
    label: "Owner",
  },
  {
    key: "key_client_contact",
    label: "Key Client Contact",
  },
  {
    key: "start_date",
    label: "Start Date",
  },
  {
    key: "engagement_length",
    label: "Engagement Length",
  },
  {
    key: "upload_contract",
    label: "Upload Contract",
    render: (v) => renderDoc("doc", "lorep ipsum.doc"),
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, data) => (
      <div className="flex gap-2">
        <Button
          className="text-xs"
          variant="naked"
          onClick={() => handleRowClick(data.engagement_name)}
        >
          View Detail
        </Button>
      </div>
    ),
  },
];
