import { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Search,
  FolderLock,
  FolderOpen,
  Folder,
  Users2,
} from "lucide-react";

const HistoryItem = ({ title, isActive, onClick }) => (
  <div
    className={`-mx-4 px-4 py-2 cursor-pointer rounded ${
      isActive ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50"
    }`}
    onClick={onClick}
  >
    <div
      className={`truncate ${
        isActive ? " text-blue-600" : "hover:bg-gray-50 text-secondary"
      }`}
    >
      {title}
    </div>
  </div>
);

const WorkstreamItem = ({
  icon: Icon,
  title,
  children,
  defaultExpanded = false,
  isActive,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div>
      <div
        className={`flex items-center px-4 py-2 cursor-pointer text-secondary rounded hover:bg-gray-50 ${
          isActive ? "bg-blue-50 text-blue-600" : ""
        }`}
        onClick={() => {
          if (children) {
            setIsExpanded(!isExpanded);
          } else {
            onClick();
          }
        }}
      >
        {Icon && <Icon className="w-4 h-4 mr-2 text-gray-500 flex-shrink-0" />}
        <span className="flex-1 truncate text-sm">{title}</span>
        {children && (
          <ChevronDown
            className={`w-4 h-4 transition-transform flex-shrink-0 ${
              isExpanded ? "rotate-180" : ""
            }`}
          />
        )}
      </div>
      {isExpanded && children && (
        <div className={`ml-4 border-gray-200 text-secondary`}>{children}</div>
      )}
    </div>
  );
};

export default function ChatHistorySidebar() {
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(true);
  const [isWorkstreamsExpanded, setIsWorkstreamsExpanded] = useState(true);
  const [activeItem, setActiveItem] = useState("Cash Revenue Project Alpha");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <div className="">
      <div>
        <div
          className="flex items-center px-2 py-2 cursor-pointer"
          onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
        >
          {isHistoryExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span
            className="flex-1 text-gray-700 text-sm"
            style={{ fontSize: "14px" }}
          >
            History
          </span>
        </div>

        {isHistoryExpanded && (
          <div className="px-2 py-2">
            <div className="relative -mx-4">
              <Search className="absolute left-3 top-2.5 w-4 h-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search keyword.."
                className="w-full pl-9 pr-4 py-2 border border-gray-200 rounded-md text-sm"
                style={{ fontSize: "14px" }}
              />
            </div>

            <div className="h-[280px] overflow-y-auto -mx-4 px-4 ">
              <div className="mt-4">
                <div
                  className="text-sm font-medium mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Today
                </div>
                <HistoryItem
                  title="Cash Revenue Project Alpha"
                  isActive={activeItem === "Cash Revenue Project Alpha"}
                  onClick={() => handleItemClick("Cash Revenue Project Alpha")}
                />
                <HistoryItem
                  title="Is Project Alpha Good Investment?"
                  isActive={activeItem === "Is Project Alpha Good Investment?"}
                  onClick={() =>
                    handleItemClick("Is Project Alpha Good Investment?")
                  }
                />
                <HistoryItem
                  title="What makes Project Alpha a good investment?"
                  isActive={
                    activeItem === "What makes Project Alpha a good investment?"
                  }
                  onClick={() =>
                    handleItemClick(
                      "What makes Project Alpha a good investment?"
                    )
                  }
                />
              </div>

              <div className="mt-4">
                <div
                  className="text-sm font-medium mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Yesterday
                </div>
                <HistoryItem
                  title="Cash Revenue Project Alpha"
                  isActive={
                    activeItem === "Cash Revenue Project Alpha (Yesterday)"
                  }
                  onClick={() =>
                    handleItemClick("Cash Revenue Project Alpha (Yesterday)")
                  }
                />
                <HistoryItem
                  title="Is Project Alpha Good Investment?"
                  isActive={
                    activeItem ===
                    "Is Project Alpha Good Investment? (Yesterday)"
                  }
                  onClick={() =>
                    handleItemClick(
                      "Is Project Alpha Good Investment? (Yesterday)"
                    )
                  }
                />
                <HistoryItem
                  title="What makes Project Alpha a good investment?"
                  isActive={
                    activeItem ===
                    "What makes Project Alpha a good investment? (Yesterday)"
                  }
                  onClick={() =>
                    handleItemClick(
                      "What makes Project Alpha a good investment? (Yesterday)"
                    )
                  }
                />
                <HistoryItem
                  title="Cash Revenue Project Alpha"
                  isActive={
                    activeItem === "Cash Revenue Project Alpha (Yesterday 2)"
                  }
                  onClick={() =>
                    handleItemClick("Cash Revenue Project Alpha (Yesterday 2)")
                  }
                />
              </div>

              <div className="mt-4">
                <div
                  className="text-sm font-medium mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Previous 7 Days
                </div>
                <HistoryItem
                  title="Cash Revenue Project Alpha"
                  isActive={
                    activeItem === "Cash Revenue Project Alpha (7 Days)"
                  }
                  onClick={() =>
                    handleItemClick("Cash Revenue Project Alpha (7 Days)")
                  }
                />
                <HistoryItem
                  title="Is Project Alpha Good Investment?"
                  isActive={
                    activeItem === "Is Project Alpha Good Investment? (7 Days)"
                  }
                  onClick={() =>
                    handleItemClick(
                      "Is Project Alpha Good Investment? (7 Days)"
                    )
                  }
                />
                <HistoryItem
                  title="What makes Project Alpha a good investment?"
                  isActive={
                    activeItem ===
                    "What makes Project Alpha a good investment? (7 Days)"
                  }
                  onClick={() =>
                    handleItemClick(
                      "What makes Project Alpha a good investment? (7 Days)"
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
      {/* Workstreams Section */}
      <div className="mt-4">
        <div
          className="flex items-center px-2 py-2 cursor-pointer"
          onClick={() => setIsWorkstreamsExpanded(!isWorkstreamsExpanded)}
        >
          {isWorkstreamsExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span
            className="flex-1 text-gray-700 text-sm"
            style={{ fontSize: "14px" }}
          >
            Workstreams
          </span>
        </div>

        {isWorkstreamsExpanded && (
          <div>
            <WorkstreamItem
              icon={Users2}
              title="Workforce Optimization"
              defaultExpanded={true}
              isActive={activeItem === "Workforce Optimization"}
              onClick={() => handleItemClick("Workforce Optimization")}
            >
              <WorkstreamItem
                icon={FolderLock}
                title="Private Folder"
                isActive={activeItem === "Private Folder"}
                onClick={() => handleItemClick("Private Folder")}
              >
                <div
                  className={`pl-6 py-2 text-sm truncate cursor-pointer ${
                    activeItem === "Cash Revenue Project Alpha (Private)"
                      ? "bg-blue-50 text-blue-600"
                      : "hover:bg-gray-50"
                  }`}
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                    handleItemClick("Cash Revenue Project Alpha (Private)")
                  }
                >
                  Cash Revenue Project Alpha
                </div>
                <div
                  className={`pl-6 py-2 text-sm truncate cursor-pointer ${
                    activeItem === "Is Project Alpha Good Investment? (Private)"
                      ? "bg-blue-50 text-blue-600"
                      : "hover:bg-gray-50"
                  }`}
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                    handleItemClick(
                      "Is Project Alpha Good Investment? (Private)"
                    )
                  }
                >
                  Is Project Alpha Good Investment?
                </div>
              </WorkstreamItem>

              <WorkstreamItem
                icon={FolderOpen}
                title="Shared Folder"
                isActive={activeItem === "Shared Folder"}
                onClick={() => handleItemClick("Shared Folder")}
              />

              <WorkstreamItem
                icon={Folder}
                title="Technology and Automation"
                isActive={activeItem === "Technology and Automation"}
                onClick={() => handleItemClick("Technology and Automation")}
              />

              <WorkstreamItem
                icon={Folder}
                title="Policy and Cost Analysis"
                isActive={activeItem === "Policy and Cost Analysis"}
                onClick={() => handleItemClick("Policy and Cost Analysis")}
              />

              <WorkstreamItem
                icon={Folder}
                title="Business Planning"
                isActive={activeItem === "Business Planning"}
                onClick={() => handleItemClick("Business Planning")}
              />

              <WorkstreamItem
                icon={Folder}
                title="Value Creation"
                isActive={activeItem === "Value Creation"}
                onClick={() => handleItemClick("Value Creation")}
              />
            </WorkstreamItem>
          </div>
        )}
      </div>
    </div>
  );
}
