import { postData } from "../../utils/apiUtils";

/**
 * Sends a POST request to the /placeholder endpoint with the provided input data.
 *
 * @param {any} input - The input data to be sent in the request body.
 * @returns {Promise<any>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const loginService = async (input) => {
  try {
    const response = await postData("/login", input);
    localStorage.setItem('auth_key', response?.auth_key)
    return response?.auth_key;
  } catch (error) {
    console.error("Error in login request:", error);
    throw error;
  }
};
