import {
  User,
  Users,
  DollarSign,
  FileText,
  CalendarCheck,
  CalendarClock,
  CircleDollarSignIcon,
  SquareUser,
} from "lucide-react";
import DataTable from "../../../components/DataTable";
import { columns, renderIconDoc } from "./data";
import { useState } from "react";
import BackgroundStep from "./backgrund-step";

export default function EngagementDetails() {
  const [files, setFiles] = useState([
    {
      id: "1",
      img: "https://i.pravatar.cc/64",
      name: "John Doe",
      email: "xT9oV@example.com",
      date_added: "01/01/2023",
    },
    {
      id: "2",
      img: "https://i.pravatar.cc/64",
      name: "John Doe 2",
      email: "zasa123@example.com",
      date_added: "01/01/2023",
    },
    {
      id: "3",
      img: "https://i.pravatar.cc/64",
      name: "John Doe 3",
      email: "vsdf3232@example.com",
      date_added: "01/01/2023",
    },
  ]);
  return (
    <div className="border rounded-xl mt-4 p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <FileText className="w-[18px] h-[18px] text-gray-500" />
          <h1 className="text-[15px]  text-primary">Engagement Details</h1>
        </div>

        <div className="flex gap-2 items-center ">
          {renderIconDoc("pdf", "report.pdf")}
        </div>
      </div>

      {/* Key Information Grid */}
      <hr className="-mx-4" />
      <div className="flex">
        <div className="grid grid-cols-1">
          <div className="grid grid-cols-5">
            <div className="flex items-center gap-3  bg-white m-4">
              <User className="w-5 h-5 text-gray-500 mt-0.5" />
              <div>
                <p className="text-xs text-gray-600 font-helveticaNeue font-normal">Owner</p>
                <p className="font-medium">John Doe</p>
              </div>
            </div>

            <div className="flex items-center gap-3  bg-white  border-l pl-4 m-4 border-gray-200">
              <SquareUser className="w-5 h-5 text-gray-500 mt-0.5" />
              <div>
                <p className="text-xs text-gray-600 font-helveticaNeue font-normal">Key Client Contact</p>
                <p className="font-medium">Natalia</p>
              </div>
            </div>

            <div className="flex items-center gap-3 m-4  bg-white border-l pl-4 border-gray-200">
              <CalendarClock className="w-5 h-5 text-gray-500 mt-0.5" />
              <div>
                <p className="text-xs text-gray-600 font-helveticaNeue font-normal">Start Date</p>
                <p className="font-medium">02/12/2024</p>
              </div>
            </div>

            <div className="flex items-center gap-3 m-4  bg-white border-l pl-4 border-gray-200">
              <CalendarCheck className="w-5 h-5 text-gray-500 mt-0.5" />
              <div>
                <p className="text-xs text-gray-600 font-helveticaNeue font-normal">End Date</p>
                <p className="font-medium">02/05/2025</p>
              </div>
            </div>

            <div className="flex items-center gap-3 m-4  bg-white border-l pl-4 border-gray-200">
              <CircleDollarSignIcon className="w-5 h-5 text-gray-500 mt-0.5" />
              <div>
                <p className="text-xs text-gray-600 font-helveticaNeue font-normal">Contract Value</p>
                <p className="font-medium">12 Month</p>
              </div>
            </div>
          </div>

          <hr className="mr-4  " />
          <div className="flex flex-row gap-8 py-5 -mt-5">
            <div className="pr-4">
              <h2 className="font-medium text-primary mb-2">
                Statement of Work
              </h2>
              <p className="text-[#222222] font-helveticaNeue font-normal leading-relaxed pr-5 ">
                Engagement to analyze how to sustainably reduce staffing costs
                across the hospitality chain by improving operational
                efficiency, leveraging technology, and optimizing workforce
                structures. This will be achieved without compromising service
                quality or compliance with UK labor regulations
              </p>
            </div>
          </div>
          <hr className="mr-4 -mt-4 " />

          <div className="flex flex-col gap-8 pt-5 -mt-6">
            <div className="">
              <div className="mb-4">
                <h2 className="font-medium text-primary mb-2">Teams</h2>
              </div>
              <div className="border-x rounded-md border-b max-w-[98%] mb-2">
                <DataTable
                  columns={columns({ handleRowClick: () => {} })}
                  data={files}
                  className="mt-4"
                />
              </div>
            </div>
          </div>
        </div>

        <BackgroundStep />
      </div>

      {/* Main Content */}
    </div>
  );
}
