import DocsFillSvg from "../../icons/DocsFillSvg";

export const renderDocTypeChat = (type) => {
  if (type === "pdf") {
    return (
      <div className="my-auto font-productSans border rounded border-orange-400 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-orange-600">
          <DocsFillSvg />
        </span>
        <b className="uppercase">{type}</b>
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="my-auto font-productSans border rounded border-green-400 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
        <b className="uppercase">{type}</b>
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="my-auto font-productSans border rounded border-blue-400 py-1 px-2 !text-xs flex gap-1">
        <span className="m-auto text-blue-600">
          <DocsFillSvg />
        </span>
        <b className="uppercase">{type}</b>
      </div>
    );
  }
  return (
    <div className="my-auto font-productSans border rounded border-gray-400 py-1 px-2 !text-xs flex gap-1">
      <span className="m-auto text-gray-600">
        <DocsFillSvg />
      </span>
      <b className="uppercase">{type}</b>
    </div>
  );
};
