import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataTable from "../../../components/DataTable";
import { columnsRecive, columnsRequest } from "./data";
import { listDocumentService } from "../../../service/document";
import { Tabs } from "../../../components/Tabs";
import { engagementsTabData } from "../../../constants/tabs";
import EngagementsHeader from "../header";
import UploadData from "./upload";
import DocumentCategory from "./category";
import DocumentSubmit from "./submit";
import DocumentPrompt from "./prompt";

const EngagementsDocuments = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step") || "1";
  const tab = searchParams.get("tab") || "existing";

  const [files, setFiles] = useState([]);
  const [dataRequest, setDataRequest] = useState([
    {
      id: "1",
      name_of_request: "Revenue data venue and ecomm",
      function: "Compliance",
      date_of_request: "01/12/2024",
      email: "client@gmail.com",
    },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Get list of files on component mount
    const fetchFiles = async () => {
      setLoading(true);
      try {
        const fileList = await listDocumentService();
        setFiles(fileList);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
      setLoading(false);
    };

    fetchFiles();
  }, []);

  const handleQuery = (query) => {
    console.log("q:", query);
  };

  const handleRowClick = (name) => {};

  return (
    <div className="rounded-md text-primary">
      <EngagementsHeader />
      <Tabs data={engagementsTabData} />

      {step === "1" && <UploadData />}
      {step === "2" && tab === "existing" && <DocumentCategory />}
      {step === "2" && tab === "generate" && <DocumentPrompt />}
      {step === "3" && <DocumentSubmit />}

      {step === "1" && (
        <>
          {" "}
          <div className="rounded-md border bg-[#FDFEFF] mt-6">
            <div className="flex justify-between w-full mt-4 pr-4">
              <div className="pl-6 flex align-middle gap-2 ">
                <span className="text-lg my-auto">Received Documents</span>
              </div>
            </div>

            <DataTable
              loading={loading}
              columns={columnsRecive({ handleRowClick })}
              data={files}
              onQuery={handleQuery}
              className="mt-4"
            />
          </div>
          <div className="rounded-md border bg-[#FDFEFF] mt-6">
            <div className="flex justify-between w-full mt-4 pr-4">
              <div className="pl-6 flex align-middle gap-2 ">
                <span className="text-lg my-auto">Data Request</span>
              </div>
            </div>

            <DataTable
              loading={loading}
              columns={columnsRequest({ handleRowClick })}
              data={dataRequest}
              onQuery={handleQuery}
              className="mt-4"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EngagementsDocuments;
