import React, { useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import DocumentPreviewHeader from "./header";
import { Tabs } from "../../../../components/Tabs";
import { documentPreviewTabData } from "../../../../constants/tabs";

function DocumentPreviewOverview() {
  const [documentData] = useState({
    fileName: "evenue data venue and ecomm",
    fileType: "pdf",
    size: "2.5Mb",
    uploadDate: "December 12, 2024",
    summary:
      "This document contains tabs relating to the total sales across various hospitality venues across the UK. 95% of revenue comes from traditional hospitality (restaurant and hotelling) with the remaining 5% from ecommerce and events. Analysis covers locations includes locations separated into 4 categories of London Central, London Outer, City Hub, and Country.",
    fileUrl: "https://pdfobject.com/pdf/sample.pdf",
  });

  const docs = [{ uri: documentData.fileUrl }];

  const detailItems = [
    { label: "File Name", value: documentData.fileName },
    { label: "File Type", value: documentData.fileType },
    { label: "Size", value: documentData.size },
    { label: "Upload Date", value: documentData.uploadDate },
  ];

  return (
    <div>
      <DocumentPreviewHeader />
      <Tabs data={documentPreviewTabData} />

      <div className="flex space-x-4 mt-6">
        <div className="w-1/2 border rounded-lg p-4">
          <h2 className="text-lg mb-4">Details</h2>
          <div className="space-y-2">
            {detailItems.map((item, index) => (
              <React.Fragment key={item.label}>
                <div className="flex justify-between">
                  <span className="text-secondary">{item.label}:</span>
                  <span className="text-primary">{item.value}</span>
                </div>
                {index < detailItems.length - 1 && (
                  <hr className="border-gray-200" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="w-1/2 border rounded-lg p-4">
          <h2 className="text-lg font-medium mb-2">Summary</h2>
          <p>{documentData.summary}</p>
        </div>
      </div>

      <div className="mt-6">
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
            },
          }}
        />
      </div>
    </div>
  );
}

export default DocumentPreviewOverview;
