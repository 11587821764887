import React from "react";

import { Tabs } from "../../../components/Tabs";
import { engagementsTabData } from "../../../constants/tabs";
import EngagementDetails from "./detail";
import EngagementsHeader from "../header";

const EngagementsHome = () => {
  return (
    <div className="rounded-md text-primary">
      <EngagementsHeader />
      <Tabs data={engagementsTabData} />
      <EngagementDetails />
    </div>
  );
};

export default EngagementsHome;
