import { BriefcaseBusiness } from "lucide-react";

export const menuItems = [
  {
    label: "Company", // Label Grup Menu
    items: [
      {
        label: "Ivy Collection",
        icon: <BriefcaseBusiness className="w-5 h-5 " />,
        child: [
          { path: "/homepage", label: "Home" },
          { path: "/engagements", label: "Engagements" },
          { path: "/access", label: "Access/Staffing" },
        ],
      },
    ],
  },

  // {
  //   label: "Clients", // Label Grup Menu
  //   items: [
  //     {
  //       label: "UBS",
  //       icon: <BriefcaseBusiness className="w-5 h-5" />,
  //       child: [
  //         { path: "/ubs/homepage", label: "Home" },
  //         { path: "/ubs/engagements", label: "Engagements" },
  //         { path: "/ubs/access", label: "Access/Staffing" },
  //       ],
  //     },
  //     {
  //       label: "Hampden & Co",
  //       icon: <BriefcaseBusiness className="w-5 h-5" />,
  //       child: [
  //         { path: "/hampden/homepage", label: "Home" },
  //         { path: "/hampden/engagements", label: "Engagements" },
  //         { path: "/hampden/access", label: "Access/Staffing" },
  //       ],
  //     },
  //     {
  //       label: "Titanbay",
  //       icon: <BriefcaseBusiness className="w-5 h-5" />,
  //       child: [
  //         { path: "/titanbay/homepage", label: "Home" },
  //         { path: "/titanbay/engagements", label: "Engagements" },
  //         { path: "/titanbay/access", label: "Access/Staffing" },
  //       ],
  //     },
  //   ],
  // },
];
