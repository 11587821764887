import React from "react";
import StepperItem from "../../../components/StepperItem";

const steps = [
  {
    date: "20 Mar 2024",
    title: "Call with AI team to discuss requirements with client",
    active: true,
  },
  {
    date: "03 Mar 2024",
    title: "Further credentials shared with client",
    active: false,
  },
  {
    date: "28 Feb 2024",
    title: "Follow up call with CEO",
    active: false,
  },
  {
    date: "15 Feb 2024",
    title: "RFP submitted for initial pitch",
    active: false,
  },
  {
    date: "23 Jan 2024",
    title: "Initial contact made with client (through K. Smith)",
    active: false,
  },
];

const BackgroundStep = () => {
  return (
    <div className="max-w-lg mx-auto p-6 border-l border-borderbase mt-4">
      <h2 className="text-[14px] text-medium mb-4">Background</h2>
      <div className="relative">
        {steps.map((step, index) => (
          <StepperItem
            key={index}
            date={step.date}
            title={step.title}
            active={step.active}
            isLast={index === steps.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default BackgroundStep;
